<template>
  <v-row justify="center">
    <div v-if="dialog && role">
      <v-dialog
        :value="dialog"
        max-width="50%"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="$emit('onCloseDialog')">
              <i class="fas fa-window-close"></i>
            </v-btn>
            <v-toolbar-title>{{
              !role._id ? $t("title.create_role") : $t("title.update_role")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :disabled="loading" text @click="validate">
                {{ !role._id ? $t("button.create") : $t("button.update") }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Loading :visible="loading" />
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="role.role_name"
                      :rules="rules.role_name"
                      :label="$t('input.role_name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="role.descr"
                      :rules="rules.descr"
                      :label="$t('input.description')"
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col>
                      <v-treeview
                        v-model="selected"
                        :items="treeMenu"
                        selectable
                        activatable
                        return-object
                        open-on-click
                        @input="select($event)"
                      ></v-treeview>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    treeMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selection: {
      type: Array,
      event: "changeSelect",
      default: () => {
        return [];
      },
    },
    rules: {
      type: Object,
      default: function () {
        return {
          role_name: [(v) => !!v || "Name is required"],
          descr: [(v) => !!v || "description is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    role: {
      type: Object,
      default: function () {
        return {
          role_name: null,
          descr: null,
          menu: [],
        };
      },
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
    selected: {
      get() {
        return this.selection;
      },
      set(value) {
        let data = value.map((res) => {
          return {
            id: res.id,
          };
        });
        this.$emit("changeSelect", data);
      },
    },
  },
  methods: {
    select(e) {
      let objectMenu = [];
      for (let i in e) {
        let keys = e[i].id.split("-");
        if (keys.length > 1)
          objectMenu.push({ menu_id: keys[0], permission_id: keys[1] });
        else objectMenu.push({ menu_id: keys[0], permission_id: null });
      }
      let groupMenu = objectMenu.reduce((r, a) => {
        if (a.menu_id)
          r[a.menu_id] = [...(r[a.menu_id] || []), a.permission_id];
        return r;
      }, {});
      const format = [];
      for (const i in groupMenu) {
        format.push({
          menu_id: i,
          permissions: groupMenu[i],
        });
      }
      console.log()
      this.role.menu = format;
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
